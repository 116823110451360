export default [
  {
    path: '/appManagement',
    name: 'appManagement',
    component: () => import(/* webpackChunkName: "appManagement" */ './index'),
    meta: {
      type: 'appManagement'
    },
    redirect: '/appManagement/appList',
    children: [
      {
        path: 'appList',
        name: 'appList',
        component: () => import(/* webpackChunkName: "appManagement" */ './appList')
      },
      {
        path: 'versionManagement',
        name: 'versionManagement',
        component: () => import(/* webpackChunkName: "appManagement" */ './versionManagement')
      },
      {
        path: 'publishApp',
        name: 'publishApp',
        component: () => import(/* webpackChunkName: "appManagement" */ './publishApp')
      },
      {
        path: 'processProgress',
        component: () => import(/* webpackChunkName: "appManagement" */ './processProgress'),
        redirect: '/processProgress/index',
        children: [
          {
            path: 'index',
            name: 'processProgress',
            component: () =>
              import(
                /* webpackChunkName: "appManagement" */ './processProgress/views/operateList.vue'
              )
          },
          {
            path: 'appDetail/:id/:type',
            name: 'processProgressDetail',
            component: () =>
              import(
                /* webpackChunkName: "appManagement" */ './processProgress/views/appDetail.vue'
              )
          }
        ]
      }
    ]
  }
]
